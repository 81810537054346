import "./App.css";
import Home from "./Home";
import React from "react";
import "semantic-ui-css/semantic.min.css";

function App() {
	return (
		<div className="App">
			<div>
				<Home />
			</div>
		</div>
	);
}

export default App;
